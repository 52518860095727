import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

import ContactForm from '../components/features/ContactForm';
import { ContainerSm } from '../components/layout/Container';
import { Grid } from '../components/layout/Grid';
import { TextLink } from '../components/shared/TextLink';

export default function Contact() {

    const variants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.45,
                delayChildren: 0.1
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Fredrik Yumo - UX/UI Design - Contact</title>
            </Helmet>
            <ContainerSm variants={variants} initial="hidden" animate="visible">
                <motion.div variants={variants}>
                    <h1>Let's Connect</h1>
                    <TextLink href="https://drive.google.com/file/d/1K-H0BJUSdBObZ3yDfFUpMxfWIlmm6oz_/view?usp=sharing" target="_blank" rel="noreferrer noopener">View My Resume</TextLink>
                    <ContactForm />
                </motion.div>
            </ContainerSm>
        </>
    );
}