import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';
import { Grid } from '../layout/Grid';

const StyledImageModal = styled.div`
    display: ${props => props.open ? 'block' : 'none'};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    overflow: hidden;
    z-index: 17;
    
    svg {
        position: absolute;
        z-index: 12;
        top: 5%;
        left: 98%;
        transform: translate(-98%, -5%);
        width: 24px;
        height: 24px;
        fill: var(--light);
    }

    div:first-child {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        overflow: auto;
    }

    @media (min-width: 48rem) {
        div:first-child {
            left: 50%;
            transform: translate(-50%, -50%);

            img {
                width: auto;
                max-width: 95vw;
                max-height: 95vh;
            }
        }
    }
`;

const StyledImageModalBtn = styled.div`
    cursor: pointer;
    margin: 16px 0;

    ${Grid} & {
        margin: 0;
    }
`;

export default function ImageModal(props) {
    const [open, setOpen] = useState(false);

    const handleClick = () => setOpen(!open);

    return (
        <>
            <StyledImageModalBtn>
                <img 
                    sizes="(min-width: 30em) 28em, 100vw"
                    srcSet={`
                        ${props.srcSetSm},
                        ${props.srcSetMd},
                        ${props.srcSetLg}
                    `}
                    alt={props.alt} 
                    onClick={handleClick} 
                />
                <StyledImageModal open={open} onClick={handleClick}>
                    <div>
                        <img src={props.src} alt={props.alt} />
                    </div>
                    <CloseIcon onClick={handleClick} />
                </StyledImageModal>
            </StyledImageModalBtn>
        </>
    );
}

ImageModal.propTypes = {
    img: PropTypes.string,
    alt: PropTypes.string
}