import React from 'react';
import { Link } from 'react-router-dom';
import Emoji from 'react-emoji-render';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

import Button from '../components/shared/Button';
import Card from '../components/shared/Card';
import { ContainerMd } from '../components/layout/Container';
import { Grid } from '../components/layout/Grid';

export default function Home() {
    const variants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.45,
                delayChildren: 0.1
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Fredrik Yumo - UX/UI Design</title>
            </Helmet>
            <ContainerMd variants={variants} initial="hidden" animate="visible">
                <Grid cols={2} gap={1} variants={variants}>
                    <div>
                        <h1>Fredrik Yumo</h1>
                        <p><Emoji text=":v:" /> Hi! I'm a Seattle-based UX/UI designer focused on solving complex problems and making life a little easier through visual design and technology.</p>
                    </div>
                </Grid>
                <Grid cols={2} gap={1} variants={variants}>
                    <div>
                        <Link to="/projects/travlr">
                            <Card img="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/travlr/travlr-card-sm_b65yci.webp" alt="Travlr Title Card links to Travlr Case Study">Travlr</Card>
                        </Link>
                    </div>
                    <div>
                        <Link to="/projects/fluently">
                            <Card img="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/fluently/fluently-card-sm_q8pxrw.webp" alt="Fluently Title Card Small links to Fluently Case Study">Fluently</Card>
                        </Link>
                    </div>
                    <div>
                        <Link to="/projects/wavelength">
                            <Card img="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/wavelength/wavelength-card_hhnefl.webp" alt="Wavelength Title Card Small links to Wavelength Case Study">Wavelength</Card>
                        </Link>
                    </div>
                    <div>
                        <Link to="/projects/roll">
                            <Card img="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/roll/roll-card_izdqqd.webp" alt="Roll App Title Card Small links to Roll App Case Study">Roll</Card>
                        </Link>
                    </div>
                    <div>
                        <Link to="/projects/the-egg-and-us">
                            <Card img="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/the-egg-and-us/the-egg-and-us-title-card_j5vcvi.webp" alt="The Egg & Us Title Card links to The Egg & US Case Study">The Egg & Us</Card>
                        </Link>
                    </div>
                </Grid>
            </ContainerMd>
            
        </>
    );
}