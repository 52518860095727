import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

import Button from '../components/shared/Button';
import { ContainerSm } from '../components/layout/Container';
import { Grid } from '../components/layout/Grid';
import ImageModal from '../components/shared/ImageModal';
import { StrongText } from '../components/shared/StrongText';

export default function TheEggAndUs() {
    const variants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.45,
                delayChildren: 0.1
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Fredrik Yumo - UX/UI Design - The Egg & Us</title>
            </Helmet>
            <ContainerSm variants={variants} initial="hidden" animate="visible">
                <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/the-egg-and-us/the-egg-and-us-title-card_j5vcvi.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/the-egg-and-us/the-egg-and-us-title-card_j5vcvi.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/the-egg-and-us/the-egg-and-us-title-card_j5vcvi.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/the-egg-and-us/the-egg-and-us-title-card_j5vcvi.webp 1440w" alt="The Egg & Us Title Card" />
                <motion.div variants={variants}>
                    <div>
                        <h1>The Egg & Us</h1>
                        <Button as="a" bg="primary" color="dark" size="md" href="https://theeggandus.com" target="_blank" rel="noreferrer noopener">Visit Website</Button>
                        <Grid cols={2} gap={1}>
                            <div>
                                <p><StrongText>Overview</StrongText></p>
                                <p>The Egg & Us is a restaurant group consisting of several brunch restaurants located around the Greater Seattle Area.</p>
                                <p>The primary goal of this project was to rebuild the website to better match the company's brand identity by making it more fun, unique, and playful.</p>
                            </div>
                            <div>
                                <p><StrongText>Role</StrongText></p>
                                <p>Visual Designer / Web Designer / Web Developer</p>
                                <p><StrongText>Project Duration</StrongText></p>
                                <p>Dec 2020 - Jan 2021</p>
                            </div>
                        </Grid>
                    </div>
                    <div>
                        <h2>Project Background</h2>
                        <p>The Egg & Us is a Seattle-based restaurant group comprised of multiple restaurants specializing in brunch and American diner style cuisine. Despite being an established 
                            player in the region's dining scene, The Egg & Us still had an outdated website with a visual design that the owner felt did not coincide with the engaging and playful 
                            brand identity they wanted to exude. Furthermore, their previous website was difficult to navigate and had very little consistency between the multiple pages it comprised of.
                        </p>
                        <p>
                            As the sole designer on this project, the majority of the design decisions fell on me with some input from my client. Alongside the visual design of the website, it also fell 
                            on me to research similar competitor sites in order to get a feel for what kind of information to include and how to possibly structure it. Although I was given a decent 
                            amount of creative freedom to make design choices, I was restricted to using the client's current logo as they did not want to spend money creating a new logo and basically 
                            rebranding their multiple restaurants.
                        </p>
                    </div>
                    <div>
                        <h2>Understanding the Problem</h2>
                        <p>
                            After speaking with the client about their wants and needs in regards to this project, I noted several frustrations that they had. First and foremost, they were unhappy with 
                            the visual design of the previous site, believing it was outdated, boring, and rather plain. Furthermore, the site design came from a tepmplate from a website building company 
                            so, not only was the client unable to modify or customize much about the site, they were also paying a hefty monthly fee that was not providing the kind of return they were 
                            hoping for. So ultimately, it was up to me to redesign the website in order to better match the client's brand identity and drive user engagement upwards.
                        </p>
                        <p>
                            To further understand my client's issues, I visited their previous website and gathered my own personal insights on its design. The first thing I noticed about the site was its 
                            inconsistency; the majority of the colors used across the website did not match the client's brand aesthetics and a lot of the design elements seemed jumbled and out-of-place. 
                            Secondly, the site itself was very difficult to navigate. Because The Egg & Us consists of multiple restaurants, the previous website did not have an easily-navigable way to find 
                            information pertaining to each restaurant; it felt clunky and confusing trying to find information regarding each restaurant. 
                        </p>
                        <p>
                            In order to better understand my client's industry, I researched several competitors and analyzed the type of content other restaurant websites highlighted as well as how they 
                            organized this content. From this research, I discovered that a lot of brunch and diner-style restaurants did not have the most modern or trendy websites; a lot of them actually 
                            looked rather old and were unresponsive. However, by researching these competitors, it gave me a good starting point for how to organize my client's website.
                        </p>
                    </div>
                    <div>
                        <h2>Prioritization of Issues</h2>
                        <p>
                            Through my research of competiting restaurant websites and my analysis of the client's previous site itself, I created a list of issues that I wanted to tackle head-on. The primary 
                            issue that I wanted to attack was the site's information architecture. Although the client's main issue revolved around the visual design of the website, it felt more important to me 
                            to ensure that users could easily navigate around the website. After all, the visual elements are a moot point if the user cannot find the information they're looking for. 
                        </p>
                        <p>
                            My second priority with this redesign was to solve the site's issue with brand consistency. The colors used on the site seemed to have been haphazardly chosen and did not connect in any 
                            way with The Egg & Us' brand identity. Through my conversations with the client, it was my understanding that they wanted to be seen as a friendly, reliable, yet playful and fun dining 
                            establishment -- values that were not evident with the appearance of the previous site. By making the site more visually consistent and reflective of the company's brand identity, I hoped to 
                            solve this design issue.
                        </p>
                    </div>
                    <div>
                        <h2>Information Architecture</h2>
                        <p>
                            Prior to doing any visual design work, I wanted to ensure that the site was organized in a concise manner so that users can find whatever information they needed easily. In order to streamline 
                            the site's navigation, I chose to contain most of the content into separate pages according to each restaurant location. By containing all the information pertaining to each restaurant location 
                            to that location's page, I believe I made the site easier to navigate and information easier to find. 
                        </p>
                    </div>
                    <div>
                        <h2>Building a Design System</h2>
                        <h3>Colors</h3>
                        <p>
                            In order to maintain consistency throughout the site and its various pages, I built a design system that I felt reflected the client's brand identity. For the color system, I chose three primary 
                            colors -- maximum yellow red, cosmic latte, and dark sky blue -- from the original The Egg & Us logo that provided some familiarity as opposed to the random yellow and green the previous site used. 
                            By creating a color system from the colors already used in the client's logo, I felt this added some consistency across the site and helped the site look more comfortable and familiar. To go along 
                            with these primary colors, I also used three colors to represent three different states -- success, warning, and error -- that could be used on alert messages and such. Furthermore, I selected colors 
                            from the four delivery apps used by The Egg & Us -- DoorDash, Grubhub, UberEats, and Postmates -- in order to style specific buttons that allow the user to order from these services.
                        </p>
                        <h3>Typography</h3>
                        <p>
                            For the typography system, I selected two different typefaces that I felt reflected the fun and playfulness of The Egg & Us. For the headings, I selected a cursive typeface called Staatliches, which 
                            was bold and unconventional. Meanwhile, I used a sans-serif typeface called Montserrat for the body copy because the wide lettering paired well with the more condensed letter widths of Staatliches. As 
                            with the color system, building the typography system first made it much easier and more efficient to stay consistent with my design and build the other design elements more quickly.
                        </p>
                        <h3>Iconography</h3>
                        <p>
                            To make the site more fun and unique, I wanted to create an egg icon for the navbar. Even though it is such a small, singular thing, I think including this icon made the site look more playful and 
                            engaging. Aside from this egg icon, I also created two more icons to be used with the alerts so that they may stand out and be more understandable.
                        </p>
                        <h3>Components</h3>
                        <p>
                            With the structure of the site figured out and the atomic elements of the site designed, I began creating components that I knew would be used consistently throughout the site. Some of these components 
                            include buttons, alerts, and cards. By building these elements out beforehand, I knew it would make the design process much quicker and more efficient so that I could provide a finished product to my 
                            client sooner than they expected.
                        </p>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/the-egg-and-us/ui-kit_p1mgtj.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/the-egg-and-us/ui-kit_p1mgtj.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/the-egg-and-us/ui-kit_p1mgtj.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/the-egg-and-us/ui-kit_p1mgtj.webp 1440w" alt="The Egg & US UI Kit" />
                    <div>
                        <h2>Designing the Solution</h2>
                        <p>
                            One of the issues with the previous website's design was that it was difficult to navigate and find information pertaining to each location since The Egg & Us had multiple locations. In order to make this easier, 
                            I redesigned the navbar at the top of the page to contain a dropdown link leading to a separate page for each restaurant location. This way, it would be easier for site visitors to find their way to each page no 
                            matter where they were on the website. Another way I made this information easier to find was by creating cards on the home page that lead to each location's specific web page. While the previous site had links to 
                            each location listed on another page, I simply moved the cards to the home page and added specific information, such as the location's address and telephone number, in order to eliminate the extra steps it would 
                            take the user to find all this information. 
                        </p>                    
                        <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/the-egg-and-us/mockup-01_zu0jg1.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/the-egg-and-us/mockup-01_zu0jg1.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/the-egg-and-us/mockup-01_zu0jg1.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/the-egg-and-us/mockup-01_zu0jg1.webp 1440w" alt="The Egg & Us Home Page" />
                        <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/the-egg-and-us/mockup-03_xilyck.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/the-egg-and-us/mockup-03_xilyck.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/the-egg-and-us/mockup-03_xilyck.webp 7682" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/the-egg-and-us/mockup-03_xilyck.webp 1440w" alt="The Egg & Us Online Ordering" />
                        <p>
                            Another issue presented by the previous site was the online ordering flow. The original site had a single page displaying each store location and containing banner links to each food delivery app according to each location. 
                            By having all these links under one page, though, this created confusion and made the page appear rather cluttered. Furthermore, the banners for the food delivery apps were inconsistent in their size and design so 
                            the page looked awkward and inconsistent. To remedy this, I designed buttons to link to each food delivery app as opposed to using their banners. I also placed these buttons on each restaurant location's 
                            separate page in order to eliminate the possibility of confusion or error. By applying these redesigns, I believe I made the site more consistent, organized, and easy-to-use. 
                        </p>
                    </div>
                    <div>
                        <h2>What I Learned</h2>
                        <p>
                            My main takeaway from this particular project was how content organization and proper information architecture could make the user experience of a website that much more engaging and easy-to-use. A lot of the content on 
                            my redesign is exactly the same as the content from the original site, but formatted and organized in a more efficient and concise way so as to avoid confusion and frustration. By properly organizing this information, I 
                            think I redesigned The Egg & Us websites in a more thoughtful and organized manner.
                        </p>
                        <p>
                            This project also taught me how to work within certain constraints. Prior to this, I typically had more free reign over the majority of my design choices, but with The Egg & Us already being an established business, this 
                            project forced me to work under specific design constraints. Due to this, I had to put much more thought and effort into creating a color and typography system that, not only made sense in conjunction with one another, but 
                            also in relation to the previous visual aesthetic of the client. 
                        </p>
                    </div>
                </motion.div>
            </ContainerSm>
        </>
    );
}