import styled from 'styled-components';

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--gap) calc(var(--gap) * 2);
    background: ${({ theme }) => theme.navbarBg};

    @media (max-width: 47.9rem) {
        margin-bottom: 94px;
    }

    @media (min-width: 48rem) {
        flex-direction: row;
        bottom: 0;
        width: 100%;
        justify-content: space-between;
    }
`;

export const FooterCopyright = styled.div`
    font-size: calc(var(--font-size) * 0.85);
    text-align: center;
`;

export const FooterNav = styled.div`
    display: flex;
    align-items: center;
`;

export const FooterNavItem = styled.a`
    margin: 0 calc(var(--gap) * 0.5);
    padding: calc(var(--gap) * 0.25);
    color: inherit;
    border-bottom: 1px solid transparent;
    transition: all 0.25s linear;
    &:hover {
        color: var(--primary);
        border-bottom: 1px solid var(--primary);
    }
`;