import React, { useState } from 'react';
import emailjs from 'emailjs-com';

import { Alert } from '../shared/Alert';
import Button from '../shared/Button';
import { Form, FormHeader, FormGroup, FormInput, FormTextarea } from '../shared/Form';
import { Grid } from '../layout/Grid';
import { TextLink } from '../shared/TextLink';

export default function ContactForm() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [alert, setAlert] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();

        const serviceId = process.env.REACT_APP_SERVICE_ID;
        const templateId = process.env.REACT_APP_TEMPLATE_ID;
        const templateParams = { name, email, subject, message };
        const userId = process.env.REACT_APP_USER_ID;

        emailjs.send(serviceId, templateId, templateParams, userId)
            .then(res => {
                setAlert(<Alert status="success" color="dark">Email has been successfully sent!</Alert>);
            })
            .catch(err => {
                setAlert(<Alert status="error" color="light">Email could not be sent!</Alert>);
            });
    }

    return (
        <Form onSubmit={handleSubmit}>
            <FormHeader>
                {alert}
            </FormHeader>
            <FormGroup>
                <FormInput
                    type="text"
                    id="name"
                    placeholder="Name"
                    aria-label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required 
                />
            </FormGroup>
            <FormGroup>
                <FormInput
                    type="email"
                    id="email"
                    placeholder="Email"
                    aria-label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required 
                />
            </FormGroup>
            <FormGroup>
                <FormInput
                    type="text"
                    id="subject"
                    placeholder="Subject"
                    aria-label="Subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required 
                />
            </FormGroup>
            <FormGroup>
                <FormTextarea
                    type="text"
                    id="message"
                    placeholder="Message"
                    aria-label="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={6}
                    required 
                />
            </FormGroup>
            <Button type="submit" bg="primary" color="dark" size="md">Submit</Button>
        </Form>
    );
}