import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

import Button from '../components/shared/Button';
import { ContainerSm } from '../components/layout/Container';
import { Grid } from '../components/layout/Grid';
import ImageModal from '../components/shared/ImageModal';
import { StrongText } from '../components/shared/StrongText';

export default function AlchemyUI() {
    const variants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.45,
                delayChildren: 0.1
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Fredrik Yumo - UX/UI Design - Alchemy UI</title>
            </Helmet>
            <ContainerSm variants={variants} initial="hidden" animate="visible">
                <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/alchemy-ui/title-card_au6ygu.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/alchemy-ui/title-card_au6ygu.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/alchemy-ui/title-card_au6ygu.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/alchemy-ui/title-card_au6ygu.webp 1440w" alt="Alchemy UI Title Card Large" variants={variants} />
                <motion.div variants={variants}>
                    <div>
                        <h1>Alchemy UI</h1>
                        <Grid cols={2} gap={1}>
                            <div>
                                <p><StrongText>Overview</StrongText></p>
                                <p>
                                    Alchemy UI is a modular design system built to make mobile app design quicker and more efficient. My goal with this project was to make my workflow faster 
                                    and more productive in order to make my future design projects much easier to complete. 
                                </p>
                                <p>
                                    This is an ongoing project that I intend to modify and add components to as I improve as a UX/UI designer and gain more knowledge and experience in designing 
                                    mobile apps.
                                </p>
                            </div>
                            <div>
                                <p><StrongText>Role</StrongText></p>
                                <p>Visual Designer</p>
                                <p><StrongText>Project Duration</StrongText></p>
                                <p>Apr 28, 2021 - Ongoing</p>
                            </div>
                        </Grid>
                        <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/alchemy-ui/Primitives_chbkxf.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/alchemy-ui/Primitives_chbkxf.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/alchemy-ui/Primitives_chbkxf.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/alchemy-ui/Primitives_chbkxf.webp 1440w" alt="Alchemy UI Primitives" />
                        <div>
                            <h2>Primitives</h2>
                        </div>
                        <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/alchemy-ui/Content_Components_hpqejy.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/alchemy-ui/Content_Components_hpqejy.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/alchemy-ui/Content_Components_hpqejy.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/alchemy-ui/Content_Components_hpqejy.webp 1440w" alt="Alchemy UI Content Components" />
                        <div>
                            <h2>Content Components</h2>
                        </div>
                        <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/alchemy-ui/Alert_Indicator_Components_dpzxb2.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/alchemy-ui/Alert_Indicator_Components_dpzxb2.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/alchemy-ui/Alert_Indicator_Components_dpzxb2.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/alchemy-ui/Alert_Indicator_Components_dpzxb2.webp 1440w" alt="Alchemy UI Alert & Indicator Components" />
                        <div>
                            <h2>Alert & Indicator Components</h2>
                        </div>
                        <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/alchemy-ui/Navigational_Components_zbmwnu.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/alchemy-ui/Navigational_Components_zbmwnu.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/alchemy-ui/Navigational_Components_zbmwnu.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/alchemy-ui/Navigational_Components_zbmwnu.webp 1440w" alt="Alchemy UI Navigational Components" />
                        <div>
                            <h2>Navigational Components</h2>
                        </div>
                        <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/alchemy-ui/Input_Components_rcqvuk.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/alchemy-ui/Input_Components_rcqvuk.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/alchemy-ui/Input_Components_rcqvuk.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/alchemy-ui/Input_Components_rcqvuk.webp 1440w" alt="Alchemy UI Input Components" />
                        <div>
                            <h2>Input Components</h2>
                        </div>
                    </div>
                </motion.div>
            </ContainerSm>
        </>
    );
}