import styled from 'styled-components';

export const TextLink = styled.a`
    display: inline;
    color: var(--primary);
    font-weight: 400;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`; 