import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerMd = styled(motion.div)`
    width: 100%;
    padding-left: calc(var(--gap) * 2);
    padding-right: calc(var(--gap) * 2);
    margin: 0 auto;

    @media (min-width: 62rem) {
        padding-right: calc(var(--gap) * 8);
        padding-left: calc(var(--gap) * 8);
    }
`;

export const ContainerSm = styled(motion.div)`
    width: 100%;
    padding-left: calc(var(--gap) * 2);
    padding-right: calc(var(--gap) * 2);

    @media (min-width: 62rem) {
        padding-right: calc(var(--gap) * 20);
        padding-left: calc(var(--gap) * 20);
    }
`;