export const lightTheme = {
    background: 'var(--light)',
    textColor: 'var(--dark)',
    navbarBg: 'var(--light)',
    toggleBg: 'linear-gradient(145deg, var(--primary), var(--light-2))',
    toggleBorder: 'var(--gray-2)',
    toggleBoxShadow: 'var(--gray-7)',
    formBg: 'var(--light)',
    formText: 'var(--dark)',
    formInputBg: 'var(--dark)',
    formInputText: 'var(--light)',
    formPlaceholderText: 'var(--gray-4)',
    formFocusText: 'var(--gray-2)',
}

export const darkTheme = {
    background: 'var(--dark)',
    textColor: 'var(--light)',
    navbarBg: 'var(--dark)',
    toggleBg: 'linear-gradient(145deg, var(--dark-2), var(--primary))',
    toggleBorder: 'var(--gray-8)',
    toggleBoxShadow: 'var(--dark)',
    formBg: 'var(--dark)',
    formText: 'var(--light)',
    formInputBg: 'var(--light)',
    formInputText: 'var(--dark)',
    formPlaceholderText: 'var(--gray-2)',
    formFocusText: 'var(--gray-7)',
}