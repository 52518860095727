import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Grid = styled(motion.div)`
    display: grid;
    margin: ${props => props.gap}rem 0;
    grid-gap: ${props => props.gap}rem;
    grid-template-columns: repeat(1, 1fr);
    
    @media (min-width: 48rem) {
        grid-template-columns: repeat(${props => props.cols}, 1fr);
    }
`;