import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

import Button from '../components/shared/Button';
import { Column } from '../components/layout/Column';
import { ContainerSm } from '../components/layout/Container';
import { Grid } from '../components/layout/Grid';
import ImageModal from '../components/shared/ImageModal';
import { StrongText } from '../components/shared/StrongText';

export default function RollApp() {
    const variants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.45,
                delayChildren: 0.1
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Fredrik Yumo - UX/UI Design - Roll</title>
            </Helmet>
            <ContainerSm variants={variants} initial="hidden" animate="visible">
                <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/roll/roll-card-lg_yfoiyu.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/roll/roll-card-lg_yfoiyu.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/roll/roll-card-lg_yfoiyu.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/roll/roll-card-lg_yfoiyu.webp 1440w" alt="Roll App Title Card Large" variants={variants} />
                <motion.div variants={variants}>
                    <div>
                        <h1>Roll</h1>
                        <Button as="a" bg="primary" color="dark" size="md" href="https://www.figma.com/proto/TVEI8gPuLLAi2Un9peIn74/roll-app-design?page-id=42%3A840&node-id=42%3A843&viewport=45%2C195%2C0.13120567798614502&scaling=scale-down" target="_blank" rel="noreferrer noopener">Visit Prototype</Button>
                        <Grid cols={2} gap={1}>
                            <div>
                                <p><StrongText>Overview</StrongText></p>
                                <p>
                                    Roll is a mobile food truck specializing in handmade sushi and Japanese street food. Prior to this project, they did not have a mobile app 
                                    or website and wanted a way to reach out to possible new customers and provide users a convenient way to order food.
                                </p>
                                <p>
                                    This project was done as part of the Google UX Design Certification course. The particular goal of this project was to design a mobile app 
                                    that was easy to use and featured accessible design. 
                                </p>
                            </div>
                            <div>
                                <p><StrongText>Role</StrongText></p>
                                <p>UX Designer / UX Researcher / Visual Designer</p>
                                <p><StrongText>Project Duration</StrongText></p>
                                <p>Mar 21, 2021 - May 06, 2021</p>
                            </div>
                        </Grid>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/roll/mockups_gckcmr.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/roll/mockups_gckcmr.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/roll/mockups_gckcmr.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/roll/mockups_gckcmr.webp 1440w" alt="Roll App Mockups" />
                    <div>
                        <h2>Understanding the Problem</h2>
                        <p>
                            The first step of my design process consisted of me conducting user interviews with a variety of different users in order to understand their 
                            problems and desires in relation to food ordering apps and websites. This user research led to two primary user groups being identified. One 
                            user group consisted of busy, working adults who did not have enough time to prepare food themselves and the other user group included "foodies" 
                            or social media influencers who enjoy food and use their social media platform to spread the word about certain foods or restaurants they like.
                        </p>
                        <p>
                            These two user groups further lead me to creating two user personas -- Anita, a busy, young professional who needs the ability to order food 
                            quickly and efficiently and Alice, a social media influencer, who needs to discover new foods in order to grow her social media followning. By 
                            creating these two user personas, it allowed me to empathize with Roll's users more and get a sense of what their goals and frustrations are. 
                        </p>
                        <p>
                            In order to further understand the problem the Roll app was trying to solve, I completed a competitive audit of Roll's direct and indirect 
                            competitors. The competitors I audited in this step included food trucks native to the Seattle area, such as Sam Choy's Poke to the Max, Marination, 
                            People of the Chubbs, Nosh, and Off the Rez. I also looked at McDonald's as an indirect competitor as well. Through this audit, I determined areas 
                            that Roll's competitors were lacking in and sough to decisively improve upon them.
                        </p>
                        <p>
                            Through my research, I determined that the primary issue I wanted to solve with my design of the Roll app was creating a quick, convenient, 
                            and efficient way for customers to place food orders. Furthermore, I wanted to make it easier for customers to see what they were ordering so 
                            as to avoid confusion and indecision.
                        </p>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/roll/user-personas_jfvhbp.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/roll/user-personas_jfvhbp.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/roll/user-personas_jfvhbp.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/roll/user-personas_jfvhbp.webp 1440w" alt="Roll App User Personas" />  
                    <div>
                        <h2>Brainstorming Ideas</h2>
                        <p>
                            For the ideation process of this project, I started by sketching out storyboards relating to the user journey I expected users to take through the Roll 
                            app. By storyboarding these user flows, I was able to get a sense for how to organize site content and how to make the user flow as fluid and 
                            uninterrupted as possible.
                        </p>
                        <p>
                            I also used the Crazy 8's method in an effort to brainstorm possible solutions to my targeted problems. The Crazy 8's method allowed me to quickly shoot 
                            out different solutions that I could further expand upon in the future if I wanted to
                        </p>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/roll/paper-wireframes_qtqzly.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/roll/paper-wireframes_qtqzly.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/roll/paper-wireframes_qtqzly.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/roll/paper-wireframes_qtqzly.webp 1440w" alt="Roll App Paper Wireframes" />
                    <div>
                        <h2>Designing the Solution</h2>
                        <p>
                            After brainstorming several ideas for design elements I wanted to include in the Roll app, I began working on my wireframes. To start off, I sketched multiple 
                            iterations of the same screen with different design choices then refined them and put them all together to get one screen that worked cohesively and looked 
                            visually appealing. This process allowed me to gain a better understanding for where content should be placed on the screen and the order the food ordering 
                            process would follow.
                        </p>
                        <p>
                            With my paper wireframes completed, I moved on to designing low-fidelity wireframes following the primary user flow of ordering items and placing an order. 
                            After finishing the design of these wireframes, I connected them to form a protoype. With this being the first iteration of the Roll app, this initial prototype 
                            was failry simple and only allowed users to test the ordering and checkout process of the app. By keeping this first iteration rather simple, it allowed to 
                            go back later on, if needed, and make changes to the design without having to redesign the entire prototype.
                        </p>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/roll/lo-fi-wireframes_qer6vq.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/roll/lo-fi-wireframes_qer6vq.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/roll/lo-fi-wireframes_qer6vq.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/roll/lo-fi-wireframes_qer6vq.webp 1440w" alt="Roll App Low-Fidelity Wireframes" />
                    <div>
                        <h2>Validating the Design</h2>
                        <p>
                            With my initial lo-fi prototype completed, I moved onto the testing phase of the design process. For this first test, I wanted to do a moderated usability study 
                            where I instructed participants to perform several key tasks within the app. The usability study lasted up to 30 minutes and I had 5 participatns -- three males 
                            and two females between the ages 24 and 31.
                        </p>    
                        <p>
                            From this usability study, I discovered three actionable insights I could work on. The first: visual cues were necessary. 
                            I discovered this insight because several of the participants were confused about whether their selected items were actually being added into their cart. Second: 
                            the checkout process was confusing. In this iteration of the app design, there were several ways users could pay for their orders. This usability study, however, 
                            revealed that this made the process too confusing for some uers. The third insight: it was confusing to reorder previously ordered items. Although all five participants 
                            thought this was a useful feature to have, they felt it was a bit unclear how to use it. 
                        </p>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/roll/sticker-sheet_vyk7lv.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/roll/sticker-sheet_vyk7lv.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/roll/sticker-sheet_vyk7lv.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/roll/sticker-sheet_vyk7lv.webp 1440w" alt="Roll App UI Kit" />
                    <div>
                        <h2>Delivering the End Product</h2>
                        <p>
                            Having gathered these insights, I began refining my low-fidelity designs with the purpose of solving the problems presented by these insights. My first priority was 
                            to create a visual cue in order to reduce the confusion my usability study participants had with adding items to their cart. To reach this goal, I added a bright red 
                            notification dot to the cart button whenever a menu item was added to show that there was something new in their cart. My second priority was to make the checkout 
                            process more streamlined and less confusing. Towards this end, I lessened the amount of different payment methods users could take and placed them behind a link instead. 
                            Lastly, I wanted to make it easier for users to reorder previously order items so I changed the labeling.
                        </p>
                        <p>
                            As part of the project's goal, I also wanted to make the Roll app accessible. In order to do this, I designed a button that provides users with a brief audio 
                            description of selected menu items. Secondly, I tested the color of text against the background color using the WCAG contrast checker to ensure that users with color 
                            blindedness or other visual impairments can easily read the test. Thirdly, I designed the buttons to be bigger and included a confirmation dialog to make sure users 
                            can perform the exact functions they intend to and go back if necessary.
                        </p>
                        <p>
                            In order to make my high-fidelity wireframes consistent and visually appealing, I created a UI kit prior to building out the wireframes. Alongside this UI kit, I also 
                            designed a minimalist logo that reflects what Roll is at its core -- a sushi restaurant. By building out this UI kit before working on the hi-fi wireframes, it made my 
                            work more efficient and consistent.
                        </p>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/roll/hi-fi-wireframes_euotp8.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/roll/hi-fi-wireframes_euotp8.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/roll/hi-fi-wireframes_euotp8.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/roll/hi-fi-wireframes_euotp8.webp 1440w" alt="Roll App High-Fidelity Wireframes" />
                    <div>
                        <h2>What I Learned</h2>
                        <p>
                            The main thing I took away from designing the Roll app is that not all users think the same way, and most of all, rarely did they think like me. By being able to test the app 
                            with multiple users, it was very beneficial because it allowed me to look at the app from a different point-of-view and see certain things I may have missed otherwise. 
                            Having never conducted usability studies or even done extensive user research like this before, learning these new skills allowed me to think about the app more in-depth and 
                            find a variety of ways to solve the problems presented.
                        </p>
                    </div>
                </motion.div>
            </ContainerSm>
        </>
    );
}
