import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Form = styled(motion.form)`
    border-radius: var(--border-radius);
    background: ${({ theme }) => theme.formBg};
    color: ${({ theme }) => theme.formText};
    overflow: hidden;
`;

export const FormHeader = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FormGroup = styled.div`
    display: flex;
    flex-direction: ${props => props.variant};
    align-items: ${props => props.variant ? 'left' : 'center'};
    margin: var(--gap) 0;
`;

export const FormLabel = styled.label`
    width: 25%;
`;

export const FormInput = styled.input`
    border-radius: var(--border-radius);
    border: 1px solid ${({ theme }) => theme.formInputBg};
    width: 100%;
    padding: calc(var(--gap) * 1.25) var(--gap);
    outline: none;
    background: transparent;
    color: ${({ theme }) => theme.formText};
    font-size: var(--font-size);
    &::placeholder {
        color: ${({ theme }) => theme.formPlaceholderText};
    }
    &:focus {
        background: ${({ theme }) => theme.formInputBg};
        color: ${({ theme }) => theme.formInputText};
        &::placeholder {
            color: ${({ theme }) => theme.formFocusText};
        }
    }

    @media (min-width: 48rem) {
        font-size: calc(var(--font-size) * 0.85);
    }
`;

export const FormTextarea = styled.textarea`
    border-radius: var(--border-radius);
    background: transparent;
    border: 1px solid ${({ theme }) => theme.formInputBg};
    width: 100%;
    padding: calc(var(--gap) * 1.25) var(--gap);
    outline: none;
    color: ${({ theme }) => theme.formText};
    font-family: inherit;
    font-size: var(--font-size);
    &::placeholder {
        color: ${({ theme }) => theme.formPlaceholderText};
    }
    &:focus {
        background: ${({ theme }) => theme.formInputBg};
        color: ${({ theme }) => theme.formInputText};
        &::placeholder {
            color: ${({ theme }) => theme.formFocusText};
        }
    }

    @media (min-width: 48rem) {
        font-size: calc(var(--font-size) * 0.85);
    }
`;