import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

import Button from '../components/shared/Button';
import { Column } from '../components/layout/Column';
import { ContainerSm } from '../components/layout/Container';
import { Grid } from '../components/layout/Grid';
import ImageModal from '../components/shared/ImageModal';
import { StrongText } from '../components/shared/StrongText';

export default function Wavelength() {
    const variants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.45,
                delayChildren: 0.1
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Fredrik Yumo - UX/UI Design - Wavelength</title>
            </Helmet>
            <ContainerSm variants={variants} initial="hidden" animate="visible">
                <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/wavelength/wavelength-card-lg_vkashb.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/wavelength/wavelength-card-lg_vkashb.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/wavelength/wavelength-card-lg_vkashb.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/wavelength/wavelength-card-lg_vkashb.webp 1440w" alt="Wavelength Title Card Large" variants={variants} />
                <motion.div variants={variants}>
                    <div>
                        <h1>Wavelength</h1>
                        <Grid cols={2} gap={1}>
                            <Button as="a" bg="primary" color="dark" size="md" href="https://www.figma.com/proto/jvrRMQppwXz4pf8YIsXDr1/wavelength-site-design?page-id=54%3A255&node-id=54%3A257&viewport=455%2C224%2C0.053647372871637344&scaling=scale-down" target="_blank" rel="noreferrer noopener">Visit Mobile Prototype</Button>
                            <Button as="a" bg="primary" color="dark" size="md" variant="outline" href="https://www.figma.com/proto/jvrRMQppwXz4pf8YIsXDr1/wavelength-site-design?page-id=54%3A256&node-id=54%3A281&viewport=447%2C72%2C0.09728732705116272&scaling=min-zoom" target="_blank" rel="noreferrer noopener">Visit Desktop Prototype</Button>
                            <div>
                                <p><StrongText>Overview</StrongText></p>
                                <p>
                                    Wavelength is an online education resource where students looking for help studying can find potential study partners and set up virtual or in-person 
                                    study sessions that fit around their schedule and needs. This project was done as part of Google's UX Design Certification course.
                                </p>
                                <p>
                                    The primary focus of this project was to design an easy and efficient way for users to find study partners and schedule study sessions. A secondary goal 
                                    was to make the design responsive so that it could work for both mobile and desktop formats. This project was also done in Adobe XD as opposed to Figma 
                                    in order to help us learn how to use other design tools.
                                </p>
                            </div>
                            <div>
                                <p><StrongText>Role</StrongText></p>
                                <p>UX Designer / UX Researcher / Visual Designer</p>
                                <p><StrongText>Project Duration</StrongText></p>
                                <p>May 11, 2021 - Jun 01, 2021</p>
                            </div>
                        </Grid>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/wavelength/messages_akuhdr.png" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/wavelength/messages_akuhdr.png 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/wavelength/messages_akuhdr.png 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/wavelength/messages_akuhdr.png 1440w" alt="Wavelength Messages Mockup" />       
                    <div>
                        <h2>Understanding the Problem</h2>
                        <p>
                            In order to understand the users I was designing for, I conducted interviews with several participants and created empathy maps after gathering their feedback. 
                            Some pain points I identified through these interviews included users having difficulty finding study partners able to work around one another's schedules as 
                            well as non-English speaking users struggling to find partners whom they could effectively communicate with.
                        </p>
                        <p>
                            My user research lead to the creation of two user personas that I used to help refine and pinpoint my design to work on specific needs. These two personas included 
                            Marcus, busy college student having trouble finding people to study with due to his hectic schedule, and Serena, a Spanish-speaking student who often struggles to 
                            find people she can effectively communicate with. By creating these user personas, I was able to define the issues my users faced most prominently and target them 
                            with my design.
                        </p>
                        <p>
                            The discovery phase of my design process also included a competitive audit of competitors in order to determine specific features and design choices that worked or 
                            didn't work. The competitors I researched in this step were StudyBuddy, MOOCLab, Studypal, and Chegg Study. One constant I discovered during my competitive audit of 
                            these competitors was that a majority of their sites and apps were clunky, disorganized, and contained outdated visual design -- all pitfalls I wanted to avoid. By 
                            performing this audit before designing anything, I was able to figure out certain design elements that I wanted to include and those I wanted to avoid.
                        </p>
                    </div>
                    <Grid cols={2} gap={1}>
                        <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/wavelength/user-story_pxi69m.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/wavelength/user-story_pxi69m.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/wavelength/user-story_pxi69m.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/wavelength/user-story_pxi69m.webp 1440w" alt="Wavelength User Persona - Marcus" />
                        <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/wavelength/user-story-1_k9t5nr.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/wavelength/user-story-1_k9t5nr.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/wavelength/user-story-1_k9t5nr.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/wavelength/user-story-1_k9t5nr.webp 1440w" alt="Wavelength User Persona - Serena" />
                    </Grid>
                    <div>
                        <h2>Brainstorming Ideas</h2>
                        <p>
                            The next step of my design process consisted of brainstorming various ideas of design solutions. During this step, I used the Crazy 8's method to quickly come up with 
                            several design solutions. Using the Crazy 8's method was beneficial because it was low stakes and allowed me to brainstorm multiple ideas in a quick and efficient manner 
                            without having to think too hard about it.
                        </p>
                        <p>
                            Another brainstorming method I used during this phase was creating a mind map. By building a mind map, I was able to come up with several design features 
                            I could have chosen to include in my final site design. This was beneficial because it let me keep my thoughts organized especially as I expanded upon my ideas more and more.
                        </p>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/wavelength/ia-sitemap_qrtrcb.png" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/wavelength/ia-sitemap_qrtrcb.png 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/wavelength/ia-sitemap_qrtrcb.png 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/wavelength/ia-sitemap_qrtrcb.png 1440w" alt="Wavelength Information Architecture" />
                    <div>
                        <h2>Designing the Solution</h2>
                        <p>
                            With the research and ideation phase completed, I began sketching out my design ideas on paper wireframes. AFter drafting several sketches of each screen, I selected design 
                            elements from each iteration that I liked the most and combined them to create more refined paper wireframes. Because a goal of the project was to make the site responsive, 
                            I sketched two sets of wireframes -- one for mobile and another for desktop. During this step, I sketched out the desktop designs first then translated them into mobile format 
                            so that I could figure out what elements I wanted to prioritize and how to display them on mobile.
                        </p>
                        <p>
                            After finalizing my paper wireframes, I moved onto low-fidelity wireframes and connected them to build a prototype. As with my paper wireframes, I built lo-fi wireframes of 
                            my design in both mobile and desktop format.
                        </p>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/wavelength/lofi-study-buddy-flow_y03yti.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/wavelength/lofi-study-buddy-flow_y03yti.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/wavelength/lofi-study-buddy-flow_y03yti.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/wavelength/lofi-study-buddy-flow_y03yti.webp 1440w" alt="Wavelength LoFi Study Buddy Request Flow" />
                    <div>
                        <h2>Validating the Design</h2>
                        <p>
                            To test my prototype, I conducted a moderated usability study where I instructed participants to use Wavelength's study partner request flow. For this study, I gathered a group 
                            of participants who ranged in age and gender; the one variable I wanted to control was to find at least one participant who did not speak English fluently. The goal here was to 
                            determine how easy or difficult it was for participants to find and request study partners. I also wanted to determine the responsiveness of my designs and determine which 
                            design elements worked well in regards to mobile and desktop formats so I had participants test both versions of the prototype.
                        </p>
                        <p>
                            From the results of this usability study, I discovered three insights: users needed a better way to visualize when their study sessions are and what subjects they will be studying 
                            during these sessions; users wanted the ability to meet up in-person as well as online; and users wanted the ability to create groups of multiple study partners as opposed to 
                            studying with only one other person. 
                        </p>
                        <p>
                            In order to target the problems raised by the insights gathered from my usability study, I refined my low-fidelity wireframes and further fleshed out the site, such as adding a chat 
                            feature that users could use to communicate with one another. I also added the ability for users to filter out study partners based on the langauges they spoke in order to be more 
                            accessible towards non-English speaking users.
                        </p>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/wavelength/hi-fi-study-buddy-flow_b4jpvm.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/wavelength/hi-fi-study-buddy-flow_b4jpvm.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/wavelength/hi-fi-study-buddy-flow_b4jpvm.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/wavelength/hi-fi-study-buddy-flow_b4jpvm.webp 1440w" alt="Wavelength HiFi Study Buddy Flow" />
                    <div>
                        <h2>Delivering the End Product</h2>
                        <p>
                            Before building my high-fidelity wireframes, I designed a UI kit in order to make my workflow more quick and efficient. By desgning the components of the website atomically, I was 
                            able to settle on designs and refine them before testing them out on each different screen. This made my work much more productive because I wasn't designing things on the fly and 
                            redesigning them later in order to fit the overall design of the website.
                        </p>
                        <p>
                            After completing my high-fidelity wireframes and putting them together into a working prototype, I tested my design again in an unmoderated usability study. As opposed to the moderated 
                            study I conducted before, I wanted to use an unmoderated usability study so that I could test whether users could use the site's features without my instructions. At the end of this 
                            second usability study, I found that most users were able to complete the study partner request flow and that a majority of the study participants enjoyed the visual look of the site. 
                        </p>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/wavelength/ui-kit_ocyear.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/wavelength/ui-kit_ocyear.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/wavelength/ui-kit_ocyear.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/wavelength/ui-kit_ocyear.webp 1440w" alt="Wavelength UI Kit" />
                    <div>
                        <h2>What I Learned</h2>
                        <p>
                            The primary thing I learned from this project was how to design responsively for both mobile and desktop formats. Having to design in these two different formats forced me to think 
                            more in-depth about how information would be presented and organized depending on the format. By building my wireframes first in both formats, it allowed me to think about the different 
                            ways certain design elements could fit each format.
                        </p>
                        <p>
                            This project also allowed me to get comfortable using Adobe XD. As primarily a Figma user, being forced to use Adobe XD was beneficial because it allowed me to get a better grasp for a 
                            different design tool.
                        </p>
                    </div>
                </motion.div>
            </ContainerSm>
        </>
    );
}