import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover img {
        opacity: 0.4;
    }
    &:hover div {
        opacity: 1;
    }
`;

export const CardImg = styled.img`
    border-radius: var(--border-radius);
`;

export const CardOverlay = styled.div`
    position: absolute;
    opacity: 0;
    color: ${({ theme }) => theme.textColor};
`;

export default function Card(props) {
    return (
        <CardContainer>
            <CardImg src={props.img} alt={props.alt}/>
            <CardOverlay>
                <h2>{props.children}</h2>
            </CardOverlay>
        </CardContainer>
    );
}