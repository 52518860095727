import styled from 'styled-components';

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 0%;
    max-width: 100%;

    >* {
        margin-bottom: var(--gap);
    }

    >:last-child {
        margin-bottom: 0;
    }
`;