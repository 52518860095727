import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faBehance, faDribbble } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

import { Footer, FooterNav, FooterNavItem, FooterCopyright } from '../shared/Footer';

export default function CustomFooter() {
    return (
        <Footer>
            <FooterCopyright>
                <p>&copy; Fredrik Yumo 2021 / UX Designer & Web Developer / fyumo13@gmail.com</p>
            </FooterCopyright>
            <FooterNav>
                <FooterNavItem href="mailto:fyumo13@gmail.com" target="_blank" rel="noreferrer noopener">
                    <FontAwesomeIcon icon={faEnvelope} alt="Icon link to email" />
                </FooterNavItem>
                <FooterNavItem href="https://www.linkedin.com/in/fredrikyumo/" target="_blank" rel="noreferrer noopener">
                    <FontAwesomeIcon icon={faLinkedin} alt="Icon link to LinkedIn" />
                </FooterNavItem>
                <FooterNavItem href="https://github.com/fyumo13" target="_blank" rel="noreferrer noopener">
                    <FontAwesomeIcon icon={faGithub} alt="Icon link to GitHub" />
                </FooterNavItem>
                <FooterNavItem href="https://www.behance.net/fredrik-yumo" target="_blank" rel="noreferrer noopener">
                    <FontAwesomeIcon icon={faBehance} alt="Icon link to Behance" />
                </FooterNavItem>
                <FooterNavItem href="https://dribbble.com/fredrik-yumo" target="_blank" rel="noreferrer noopener">
                    <FontAwesomeIcon icon={faDribbble} alt="Icon link to Dribbble" />
                </FooterNavItem>
            </FooterNav>
        </Footer>
    );
}