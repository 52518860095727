import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

import Button from '../components/shared/Button';
import { ContainerSm } from '../components/layout/Container';
import { Grid } from '../components/layout/Grid';
import ImageModal from '../components/shared/ImageModal';
import { StrongText } from '../components/shared/StrongText';

export default function Travlr() {
    const variants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.45,
                delayChildren: 0.1
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Fredrik Yumo - UX/UI Design - Travlr</title>
            </Helmet>
            <ContainerSm variants={variants} initial="hidden" animate="visible">
                <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/travlr/travlr-card_gxfvj3.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/travlr/travlr-card_gxfvj3.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/travlr/travlr-card_gxfvj3.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/travlr/travlr-card_gxfvj3.webp 1440w" alt="Travlr Title Card Large" variants={variants} />
                <motion.div variants={variants}>
                    <div>
                        <h1>Travlr</h1>
                        <Button as="a" bg="primary" color="dark" size="md" href="https://www.figma.com/proto/M7ZEouK4ShLuLI8LroENdi/Travlr-Design-System?page-id=49%3A606&node-id=568%3A3193&viewport=455%2C48%2C0.05&scaling=min-zoom&starting-point-node-id=568%3A3193&show-proto-sidebar=1" target="_blank" rel="noreferrer noopener">Visit Prototype</Button>
                        <Grid cols={2} gap={1}>
                            <div>
                                <p><StrongText>Overview</StrongText></p>
                                <p>Travlr is a travel planning app that helps users collaboratively plan group trips with multiple participants.</p>
                                <p>The goal of this particular project was to design an easily-navigable mobile app despite the many moving parts that comprise a group planning app.</p>
                            </div>
                            <div>
                                <p><StrongText>Role</StrongText></p>
                                <p>UX Researcher / UX Designer / Visual Designer</p>
                                <p><StrongText>Project Duration</StrongText></p>
                                <p>Jul 22, 2021 - Oct 19, 2021</p>
                            </div>
                        </Grid>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/travlr/travlr-mockup_p3btmc.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/travlr/travlr-mockup_p3btmc.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/travlr/travlr-mockup_p3btmc.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/travlr/travlr-mockup_p3btmc.webp 1440w" alt="Travlr Mockups" />
                    <div>
                        <h2>Understanding the Problem</h2>
                        <p>
                            To gain a bit of perspective on this project, I conducted multiple interviews with people who often make travel plans with groups of friends or family. Through these interviews, I discovered 
                            several pain points users encountered while planning group trips. Primarily, I learned that travelers often found themselves frustrated due to a lack of order and communication between their 
                            fellow travelers. This lead to users being confused and travel plans being disorganized or incomplete.
                        </p>
                        <p>
                            From my user research, I was able to develop two user personas -- McKenna, an avid traveler who likes to frequently take trips with her friends and family and is typically in charge of the 
                            planning, and Charles, a busy professional who tries to take at least one trip a year with his large group of friends, but often lets others do the majority of the trip planning. I took the 
                            insights learned from my user interviews and synthesized them to create these two user personas that I felt best showed the target users Travlr would be engaging with. 
                        </p>
                        <p>
                            In an effort to better understand the intricacies of travel planning, I also researched several competitors and resources similar to Travlr. In this competitive audit, I researched Mobili, Tripit, 
                            and Kayak -- three competitors that also provided travel planning services. This audit revealed that the majority of these competing apps were disorganized and information within them was difficult 
                            to find. Furthermore, most of these competitors' apps were also visually unimpressive and looked rather outdated as well. 
                        </p>
                    </div>
                    <Grid cols={2} gap={1}>
                        <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/travlr/user-story_qzczqw.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/travlr/user-story_qzczqw.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/travlr/user-story_qzczqw.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/travlr/user-story_qzczqw.webp 1440w" alt="Travlr User Persona" />
                        <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/travlr/travlr-user-story-2_piy0mi.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/travlr/travlr-user-story-2_piy0mi.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/travlr/travlr-user-story-2_piy0mi.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/travlr/travlr-user-story-2_piy0mi.webp 1440w" alt="Travlr User Persona" />
                    </Grid>
                    <div>
                        <h2>Brainstorming Ideas</h2>
                        <p>
                            The primary ideation technique I used for this project was the mind map method. I preferred to create a mind map for my ideas here because it allowed me be more organized with my thoughts especially 
                            since this project had so many different flows the user could take. Creating a mind map allowed me to further develop each user flow and expand upon them as much as I wanted.
                        </p>
                        <p>
                            Another ideation technique I used was the Crazy 8's method which allowed me to sketch out the ideas I already had from my mind map. By doing this, it gave me a better sense of how some of these ideas 
                            would appear visually.
                        </p>
                    </div>
                    <div>
                        <h2>Designing the Solution</h2>
                        <p>
                            The first step of the design phase involved me sketching out my ideas into paper wireframes. For this step, I created multiple variations of the same screen then later picked specific design elements 
                            that stood out the most and combined them to create a more refined paper wireframe for each specific screen. At the end of this phase, I had a complete paper wireframe showing each screen I wanted to 
                            build.
                        </p>
                        <p>
                            With my paper wireframes sketched out, I moved on to low-fidelity wireframes. Because Travlr contains so many extensive user flows I wanted to be able to prototype each one here. Thus, my first lo-fi 
                            wireframe was actually rather complex compared to ones I had designed before.
                        </p>
                    </div>
                    
                    <div>
                        <h2>Validating the Design</h2>
                        <p>
                            To test my prototype, I conducted a moderated usability study during which I instructed participants how to complete various tasks, such as creating a group trip, adding fellow travelers, adding 
                            transportation and lodging details, collaborating with others on activities, and assigning specific tasks to certain group members. The goal of this usability study was to see whether the user flows 
                            made sense and determined how easy or difficult it was to complete these tasks. 
                        </p>
                        <p>
                            This usability study resulted in several insights: 
                            <ul>
                                <li>
                                    The app was difficult to navigate and using tabs to navigate between certain actions was more distracting than intuitive.
                                </li>
                                <li>
                                    Better labeling was needed to avoid confusion.
                                </li>
                                <li>
                                    The trip page was cluttered and needed more organization.
                                </li>
                            </ul>
                        </p>
                        <p>
                            This feedback received from my usability study showed me that my app design had a long way to go. It forced me to go back to the drawing board and come up with better ways to organize and structure 
                            my content as that seemed to be the most frustrating issue users faced here. While the study participants did enjoy certain features of the Travlr app, the majority of them felt that it could use 
                            more refining. 
                        </p>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/travlr/travlr-ia_ylsnpr.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/travlr/travlr-ia_ylsnpr.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/travlr/travlr-ia_ylsnpr.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/travlr/travlr-ia_ylsnpr.webp 1440w" alt="Travlr Information Architecture" />
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/travlr/lofi-wireframes-mobile_ay3ro1.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/travlr/lofi-wireframes-mobile_ay3ro1.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/travlr/lofi-wireframes-mobile_ay3ro1.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/travlr/lofi-wireframes-mobile_ay3ro1.webp 1440w" alt="Travlr Low Fidelity Wireframes" />
                    <div>
                        <h2>Back to the Drawing Board</h2>
                        <p>
                            After scrapping my initial lo-fi designs, I rethought my organization of the app and decided to keep most of the content organized by trip behind a list of links as opposed to having the user switch 
                            between tabs. This way, it would eliminate the confusion switching tabs may create. Hiding the majority of the content behind these links as opposed to keeping it all on one page also made the trip 
                            page more concise and less cluttered. Lastly, I completely eliminated the discover page which was meant to help users find activities to do while on vacation, but instead turned out to be distracting 
                            and unnecessary. 
                        </p>
                        <p>
                            With my new lo-fi wireframes designed, I conducted a second moderated usability study. Like the first one, I instructed my participants to complete the same tasks and observed their reactions and feedback. 
                            From the results of this second usability study, I believe I made better decisions regarding the organization of content. The participants seemed more engaged and less confused during this usability study 
                            whereas they seemed lost and frustrated during the first one. 
                        </p>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/travlr/travlr-ui-kit_raklpw.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/travlr/travlr-ui-kit_raklpw.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/travlr/travlr-ui-kit_raklpw.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/travlr/travlr-ui-kit_raklpw.webp 1440w" alt="Travlr UI Kit" />
                    <div>
                        <h2>Delivering the End Product</h2>
                        <p>
                            Before moving on to the high-fidelity wireframes, I created a UI kit that consisted of Travlr's brand colors, a typography system, custom icons, and various components I knew I would use throughout the app. 
                            By creating this UI kit beforehand, it made my workflow more productive and efficient. 
                        </p>
                        <p>
                            With my UI kit built, I started designing my hi-fi wireframes and connecting them to build a prototype. Due to the complex nature of this project, this was probably my most in-depth and involved prototype. It 
                            displays a variety of different user flows, such as creating a group trip, adding users to said trip, adding transportation, lodging, and activity details to each trip, paying and requesting money for certain 
                            expenses from other users, and communicating between group members. 
                        </p>
                        <p>
                            To validate my designs, I once again conducted a usability study. This time, however, I chose to conduct an unmoderated study as opposed to a moderated one. With this study, I wanted to test how easy or difficult 
                            it was for users to complete those same tasks I had instructed my other study participants to do. The feedback I received in this usability study was rather positive with users commenting how easy an app like Travlr 
                            would make their group travel plans. One of the few negative comments I received about my hi-fi prototype was that it was visually boring as everything looked pretty similar. However, with the complex nature of the 
                            Travlr app, I felt this was a sacrifice that had to be made in order to reduce confusion and frustration.
                        </p>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/travlr/hifi-wireframes-mobile_mp3zxo.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/travlr/hifi-wireframes-mobile_mp3zxo.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/travlr/hifi-wireframes-mobile_mp3zxo.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/travlr/hifi-wireframes-mobile_mp3zxo.webp 1440w" alt="Travlr High Fidelity Wireframes" />
                    <div>
                        <h2>What I Learned</h2>
                        <p>
                            The biggest thing I learned from this project was that your design is never perfect and that sometimes it's necessary to go back and rethink the design choices you've made. My first low-fidelity prototype received 
                            some very negative feedback, forcing me to think harder about how I was organizing my content. However, this also lead to a more structured app that was easier to use and navigate through. 
                        </p>
                    </div>
                    
                </motion.div>
            </ContainerSm>
        </>
    );
}