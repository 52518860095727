import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

import { ContainerSm } from '../components/layout/Container';
import { Grid } from '../components/layout/Grid';

export default function About() {
    const variants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.45,
                delayChildren: 0.1
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Fredrik Yumo - UX/UI Design - About</title>
            </Helmet>
            <ContainerSm variants={variants} initial="hidden" animate="visible">
                <Grid cols={2} gap={1}>
                    <motion.div variants={variants}>
                        <h1>About Me</h1>
                        <p>
                            Born in the Philippines and based in Seattle, I am a UX/UI designer driven by a passion for art and technology. 
                            I pride myself in crafting simple and clean design with a focus on making the user experience as smooth, seamless, and 
                            fun as possible. A relentless learner, I'm always teaching myself new things and I thrive on solving difficult problems no matter the obstacles 
                            I encounter. 
                        </p>
                        <p>
                            Along with UX/UI design, I am also well-versed in front-end web development, having taught myself React after attending 
                            Coding Dojo, a 16-week coding bootcamp where I learned the fundamentals of web development. In my free time, I enjoy taking 
                            photos with my handy Canon EOS RP, cooking and trying new foods, and power lifting. 
                        </p>
                    </motion.div>
                    <motion.div variants={variants}>
                        <img src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/profile-pic_ove26a.webp" alt="Fredrik Yumo Profile Pic" />
                    </motion.div>
                </Grid>
            </ContainerSm>
        </>
    );
}

