import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

import Button from '../components/shared/Button';
import { ContainerSm } from '../components/layout/Container';
import { Grid } from '../components/layout/Grid';
import ImageModal from '../components/shared/ImageModal';
import { StrongText } from '../components/shared/StrongText';

export default function Fluently() {
    const variants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.45,
                delayChildren: 0.1
            }
        }
    }

    return (
        <>  
            <Helmet>
                <title>Fredrik Yumo - UX/UI Design - Fluently</title>
            </Helmet>
            <ContainerSm variants={variants} initial="hidden" animate="visible">
                <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/fluently/fluently-card-lg_oobunj.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/fluently/fluently-card-lg_oobunj.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/fluently/fluently-card-lg_oobunj.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/fluently/fluently-card-lg_oobunj.webp 1440w" alt="Fluently Title Card Large" variants={variants} />
                <motion.div variants={variants}>
                    <div>
                        <h1>Fluently</h1>
                        <Grid cols={2} gap={1}>
                            <Button as="a" bg="primary" color="dark" size="md" href="https://www.figma.com/proto/4rITyFd9UvXcudpP1Djh3F/fluently-app-design?page-id=31%3A35&node-id=189%3A6805&viewport=2057%2C76%2C0.22375346720218658&scaling=min-zoom" target="_blank" rel="noreferrer noopener">Visit Mobile Prototype</Button>
                            <Button as="a" bg="primary" color="dark" size="md" variant="outline" href="https://www.figma.com/proto/4rITyFd9UvXcudpP1Djh3F/fluently-app-design?page-id=189%3A5139&node-id=223%3A3312&viewport=-162%2C202%2C0.03698882460594177&scaling=min-zoom" target="_blank" rel="noreferrer noopener">Visit Desktop Prototype</Button>
                            <div>
                                <p><StrongText>Overview</StrongText></p>
                                <p>
                                    Fluently is a langauge learning app that helps users learn a new language. This was the final project in Google's UX Design Certification course.
                                </p>
                                <p>
                                    This project's focus was to make learning more fun and engaging in order to maintain and increase user involvement with the app. 
                                </p>
                            </div>
                            <div>
                                <p><StrongText>Role</StrongText></p>
                                <p>UX Designer / UX Researcher / Visual Designer</p>
                                <p><StrongText>Project Duration</StrongText></p>
                                <p>Jun 06, 2021 - Jul 01, 2021</p>
                            </div>
                        </Grid>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/fluently/mockup-00_lzyblw.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/fluently/mockup-00_lzyblw.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/fluently/mockup-00_lzyblw.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/fluently/mockup-00_lzyblw.webp 1440w" alt="Fluently Mockups" />
                    <div>
                        <h2>Understanding the Problem</h2>
                        <p>
                            In order to empathize with the target users, I conducted user interviews and created empathy maps from the feedback I received. Through this research, 
                            I discovered several pain points users encountered while using language learning apps or similar resources. The major issue I identified was that user 
                            engagement with these types of apps eventually dropped off after some time due to users either becoming bored or losing focus with the app. Another big 
                            factor I discovered was that users were more likely to use language learning apps that were convenient and easy to use.
                        </p>
                        <p>
                            From the insights gathered from my user research, I developed two user personas to represent the user groups I identified. The first persona, Alice, 
                            represented users who have a difficult time staying committed to language learning apps because they are not fun or engaging enough. The second persona, 
                            Cameron, represented users who needed a convenient way to learn a new language. By developing these two personas, I was able to further refine my design 
                            in order to target the pain points encountered by these personas. 
                        </p>
                        <p>
                            The next step in my research involved performing a competitive audit of several competitors, such as DuoLingo, Babbel, Memrise, and Rosetta Stone. By 
                            researching these competitors, I was able to determine specific areas I felt my design could improve upon. 
                        </p>
                    </div>
                    <Grid cols={2} gap={1}>
                        <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/fluently/user-story_scrv7g.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/fluently/user-story_scrv7g.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/fluently/user-story_scrv7g.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/fluently/user-story_scrv7g.webp 1440w" alt="Fluently User Persona - Alice" />
                        <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/fluently/user-story-1_nhivoo.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/fluently/user-story-1_nhivoo.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/fluently/user-story-1_nhivoo.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/fluently/user-story-1_nhivoo.webp 1440w" alt="Fluently User Persona - Cameron" />
                    </Grid>
                    <div>
                        <h2>Brainstorming Ideas</h2>
                        <p>
                            Before drafting any actual app designs, I used several ideation techniques in order to brainstorm design ideas and get a sense of how I wanted the app to 
                            look like. During this phase, I used the mind map method to list out several ideas that I had and how they connect to one another in the bigger picture. 
                            I also used the Crazy 8's method so that I could sketch out several of these ideas quickly. By using these ideation techniques, I was able to come up with 
                            a multitude of design ideas that I could further refine and expand upon later in the design phase.
                        </p>
                        <p>
                            During this process, the key idea I came up with included gamifying the process of learning a language. By turning language learning into a game, I thought 
                            it would be a good way to drive up user engagement and keep users motivated to continue using the app. Some ideas relating to this that I sketched out in 
                            this phase included the ability to choose a user avatar and earning points or currency with each lesson completed. 
                        </p>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/fluently/ia-sitemap_obh0mu.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/fluently/ia-sitemap_obh0mu.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/fluently/ia-sitemap_obh0mu.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/fluently/ia-sitemap_obh0mu.webp 1440w" alt="Fluently Information Architecture" />
                    <div>
                        <h2>Designing the Solution</h2>
                        <p>
                            With several design ideas sketched out, I moved on to paper wireframes. At this point, I sketched multiple variations of the same screen and picked out 
                            the design elements that stood out the most to me. From there, I combined them into one refined draft and repeated this process several times until I 
                            had a refined paper wireframe of each screen that I had planned out for the app.
                        </p>
                        <p>
                            Once I finalized my design choices from the initial paper wireframes, I started designing low-fidelity wireframes and connecting them to build a prototype. 
                            During this step, I had to think about how I wanted the screens organized and which sections linked to one another.
                        </p>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/fluently/lo-fi-wireframes-mobile_hoay1t.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/fluently/lo-fi-wireframes-mobile_hoay1t.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/fluently/lo-fi-wireframes-mobile_hoay1t.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/fluently/lo-fi-wireframes-mobile_hoay1t.webp 1440w" alt="Fluently Low Fidelity Mobile Wireframes" />
                    <div>
                        <h2>Validating the Design</h2>
                        <p>
                            For the first testing phase, I conducted a moderated usability study where I instructed participants to complete several tasks on the Fluently app. 
                            Some of these tasks included registering an account and customizing an avatar, completing a lesson, and adding and messaging friends. The 
                            goal of this study was to determine how engaged users were with the app and whether or not they found it enjoyable to use.
                        </p>
                        <p>
                            The results of this usability study showed three insights that I knew I could use to make Fluently's user experience more enjoyable and engaging. 
                            First, I learned that users needed better visual cues in order to tell which lessons they can or cannot complete. Secondly, users also needed 
                            better labeling on certain elements. Lastly, I discovered users needed a more efficient way to organize the courses they were taking. 
                        </p>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/fluently/ui-kit_j59ygd.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/fluently/ui-kit_j59ygd.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/fluently/ui-kit_j59ygd.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/fluently/ui-kit_j59ygd.webp 1440w" alt="Fluently UI Kit" />
                    <div>
                        <h2>Delivering the End Product</h2>
                        <p>
                            With the idea of gamifying the Fluently app in order to make it more fun and unique, I wanted to make creative design choices that would evoke the 
                            feeling of playing a video game. Towards this end, I wanted to use bright, vibrant colors and unique illustrations. Furthermore, I designed several 
                            avatars along with their customization choices in order to push the idea that Fluently is as much a game as it is a language learning app.
                        </p>
                        <p>
                            In regards to accessibility, I also included an audio button so that users would be able to listen to certain words being said during a lesson. I felt 
                            this would be beneficial so that the visually-impaired would still be able to complete their lessons and that other users could also hear the correct 
                            pronounciation of foreign words.
                        </p>
                        <p>
                            With these design choices made, I combined these elements to build several components, such as buttons, navbars, cards, and badges. By building these 
                            components first, this allowed me to move faster and more efficiently through this second design phase. Furthermore, using the prioritized insights 
                            learned from the usability study, I was able to refine my lo-fi designs to overcome the issues users faced in the first iteration. Focusing on these 
                            insights allowed me to better target this design phase on erasing these pain points.
                        </p>
                        <p>
                            After completing my high-fidelity wireframes, I began designing the desktop website to compliment Fluently's mobile app. During this step, I had to 
                            think critically about how the website should be structure and what elements should be included in order to enhance the user experience. This 
                            showed me that it is not always as easy as transferring my mobile designs to a larger screen; I had to think more about where certain elements fit 
                            and how content should be organized. Whereas the mobile app was geared more towards users who needed a fast and convenient way to learn a new language, 
                            the desktop site was designed for users who had more time to play around with the site. With this in mind, I felt making the website more game-like was 
                            the proper choice. 
                        </p>
                    </div>
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/fluently/hi-fi-wireframes-mobile_dxsbt2.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/fluently/hi-fi-wireframes-mobile_dxsbt2.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/fluently/hi-fi-wireframes-mobile_dxsbt2.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/fluently/hi-fi-wireframes-mobile_dxsbt2.webv 1440w" alt="Fluently High Fidelity Mobile Wireframes" />
                    <ImageModal src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/ux-portfolio/projects/fluently/hi-fi-wireframes-desktop_stddfq.webp" srcSetSm="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_828,dpr_auto/ux-portfolio/projects/fluently/hi-fi-wireframes-desktop_stddfq.webp 414w" srcSetMd="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_1536,dpr_auto/ux-portfolio/projects/fluently/hi-fi-wireframes-desktop_stddfq.webp 768w" srcSetLg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_2880,dpr_auto/ux-portfolio/projects/fluently/hi-fi-wireframes-desktop_stddfq.webp 1440w" alt="Fluently High Fidelity Desktop Wireframes" />
                    <div>
                        <h2>What I Learned</h2>
                        <p>
                            My main takeaway from this project was learning how to make a more unique app than the traditional ones I was used to. Designing Fluently almost felt 
                            like I was designing a game so it forced me to think outside the box about certain design choices. I feel this project was beneficial to my growth as 
                            a UX designer because it allowed me to get out of my comfort zone and come up with more unique solutions to common problems. 
                        </p>
                    </div>
                </motion.div>
            </ContainerSm>
        </>
    );
}